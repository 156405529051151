/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://51u.ab1.myftpupload.com/quienes-somos/" className={block}>
                {rtlActive ? "الصفحة الرئيسية" : "Quienes Somos?"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://secureservercdn.net/72.167.241.46/51u.ab1.myftpupload.com/wp-content/uploads/2020/11/Stacala-TERMINOS-Y-CONDICIONES-v1.0.pdf" className={block}>
                {rtlActive ? "شركة" : "Términos y Condiciones"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://secureservercdn.net/72.167.241.46/51u.ab1.myftpupload.com/wp-content/uploads/2020/11/Stacala-POLITICA-DE-PRIVACIDAD-v1.0.pdf" className={block}>
                {rtlActive ? "بعدسة" : "Pólitica de privacidad"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://secureservercdn.net/72.167.241.46/51u.ab1.myftpupload.com/wp-content/uploads/2020/12/BIBLIOGRAFIA-CONSULTADA-EN-EL-ESTUDIO-BIRD%C2%B4S-FOREST_LODGE.pdf" className={block}>
                {rtlActive ? "مدونة" : "Bibliografía y créditos fotográficos"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://secureservercdn.net/72.167.241.46/51u.ab1.myftpupload.com/wp-content/uploads/2020/11/Stacala-GLOSARIO-v1.0.pdf" className={block}>
                {rtlActive ? "مدونة" : "Glosario de la App"}
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.left}>
          <p>
          &copy; {1900 + new Date().getYear()}{" "}
          {rtlActive ? "توقيت الإبداعية" : "Creative Tim, "}
          {rtlActive ? "توقيت الإبداعية" : "Escribenos tus inquietudes info@birdsforestlodge.travel"}
          {rtlActive ? "توقيت الإبداعية" : ", o Consulta con nosotros comercial@birdsforestlodge.travel"}
          {rtlActive ? "توقيت الإبداعية" : ", ubicanos en CL 36 128 321 ED A ZF Zona América - Cali - Colombia - Sur America"}<br />
          </p>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
