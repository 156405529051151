import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import Public from "@material-ui/icons/Public";
import Phone from "@material-ui/icons/Phone";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nit: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",
      Pais: "",
      PaisState: "",
      Direccion: "",
      DireccionState: "",
      CodPostal: "",
      CodPostalState: "",
      CoordenadasX: "",
      CoordenadasXState: "",
      CoordenadasY: "",
      CoordenadasYState: "",
      Telefono1: "",
      Telefono1State: "",
      Telefono2: "",
      Telefono2State: "",
      Movil: "",
      MovilState: "",
      Whatsapp: "",
      WhatsappState: ""

    };
  }
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (
      this.state.firstnameState === "success" &&
      this.state.lastnameState === "success" &&
      this.state.PaisState === "success" &&
      this.state.CodPostalState === "success" &&
      this.state.DireccionState === "success" &&
      this.state.CoordenadasXState === "success" &&
      this.state.CoordenadasYState === "success" &&
      this.state.Telefono1State === "success" &&
      this.state.Telefono2State === "success" &&
      this.state.MovilState === "success" &&
      this.state.WhatsappState === "success" 
      //&&
      //this.state.emailState === "success"
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== "success") {
        this.setState({ firstnameState: "error" });
      }
      if (this.state.lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (this.state.PaisState !== "success") {
        this.setState({ PaisState: "error" });
      }
      if (this.state.DireccionState !== "success") {
        this.setState({ DireccionState: "error" });
      }
      if (this.state.CodPostalState !== "success") {
        this.setState({ CodPostalState: "error" });
      }
      if (this.state.CoordenadasXState !== "success") {
        this.setState({ CoordenadasXState: "error" });
      }
      if (this.state.CoordenadasYState !== "success") {
        this.setState({ CoordenadasYState: "error" });
      }
      if (this.state.Telefono1State !== "success") {
        this.setState({ Telefono1State: "error" });
      }
      if (this.state.Telefono2State !== "success") {
        this.setState({ Telefono2State: "error" });
      }
      if (this.state.MovilState !== "success") {
        this.setState({ MovilState: "error" });
      }
      if (this.state.WhatsappState !== "success") {
        this.setState({ WhatsappState: "error" });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Ingresa la información Básica al sistema para iniciar
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.firstnameState === "success"}
            error={this.state.firstnameState === "error"}
            labelText={
              <span>
                Numero de Identificación Tributaria o NIT <small>(Obligatorio)</small>
              </span>
            }
            id="firstname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "firstname", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.lastnameState === "success"}
            error={this.state.lastnameState === "error"}
            labelText={
              <span>
                Pais <small>(Obligatorio)</small>
              </span>
            }
            id="lastname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "lastname", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.PaisState === "success"}
            error={this.state.PaisState === "error"}
            labelText={
              <span>
                Departamento / Estado / Provincia <small>(Obligatorio)</small>
              </span>
            }
            id="Pais"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Pais", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Public className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.DireccionState === "success"}
            error={this.state.DireccionState === "error"}
            labelText={
              <span>
                Dirección
              </span>
            }
            id="Direccion"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Direccion", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Public className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.CodPostalState === "success"}
            error={this.state.CodPostalState === "error"}
            labelText={
              <span>
                Código Postal
              </span>
            }
            id="CodPostal"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "CodPostal", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Public className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.CoordenadasXState === "success"}
            error={this.state.CoordenadasXState === "error"}
            labelText={
              <span>
                Coordenadas (Latitud)<small> (Obligatorio)</small>
              </span>
            }
            id="CoordenadasX"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "CoordenadasX", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Public className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.CoordenadasYState === "success"}
            error={this.state.CoordenadasYState === "error"}
            labelText={
              <span>
                Coordenadas (Longitud) <small>(Obligatorio)</small>
              </span>
            }
            id="CoordenadasY"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "CoordenadasY", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Public className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <CustomInput
            success={this.state.Telefono1State === "success"}
            error={this.state.Telefono1State === "error"}
            labelText={
              <span>
                Telefono Fijo 1<small> (Indicativo)</small>
              </span>
            }
            id="Telefono1"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Telefono1", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <CustomInput
            success={this.state.Telefono2State === "success"}
            error={this.state.Telefono2State === "error"}
            labelText={
              <span>
                Telefono Fijo 2<small> (Indicativo)</small>
              </span>
            }
            id="Telefono2"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Telefono2", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <CustomInput
            success={this.state.MovilState === "success"}
            error={this.state.MovilState === "error"}
            labelText={
              <span>
                Movil<small> (Indicativo)</small>
              </span>
            }
            id="Movil"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Movil", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={5}>
          <CustomInput
            success={this.state.WhatsappState === "success"}
            error={this.state.WhatsappState === "error"}
            labelText={
              <span>
                Whatsapp<small> (Indicativo)</small>
              </span>
            }
            id="Whatsapp"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "Whatsapp", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
       
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
