import React, { Fragment } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/logo.png";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
//uso de alertas de react 
import { useAlert } from "react-alert";
//Importación de librerias de manejo de la base de datos
import ApolloClient, {gql} from "apollo-boost";
import { Query } from "react-apollo";
import { ApolloProvider } from "react-apollo";



const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const retorno='';
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
/**
 * Constantes de manejo y control de la informacion de la base de datos
 */
const client = new ApolloClient({
  uri:"https://birds-forest-and-lodge-graphql.herokuapp.com/v1/graphql"
});


const CharacterQuery = () => {
  return <Query query={gql`
                          {
                            users(where: {, password: {_eq: "@7V4N6372@"}}) {
                              avatar
                              first_name
                              id
                              last_name
                              middle_name
                              role
                              password
                              is_approved
                            }
                          }
                          `}>
                            {({ loading, error, data }) =>{
                              if(loading) return <p>Loading....</p>
                              if(error) return <p>error</p>

                              return JSON.stringify(data.users);
                            }

                            }
                          </Query>
}

function Logeo() {
  return (
    <ApolloProvider client={client}>
          <CharacterQuery />
    </ApolloProvider>
  );
}

const GET_GREETING = gql`
{
  users(where: {, password: {_eq: "@7V4N6372@"}}) {
    avatar
    first_name
    id
    last_name
    middle_name
    role
    password
    is_approved
  }
}
`;

function Hello() {
  const { loading, error, data } = Query(GET_GREETING);
  if (loading) return <p>1</p>;
  return <p>0</p>;
}
const onSubmit = () => {
  //e.preventDefault();
  console.log("Hola");
//  var retorno = Hello();
  console.log("Hola Retorno");
  //console.log(retorno);
  console.log("Data");
  //console.log(retorno.props)
  console.log("Fin data");
  //console.log(Hello());
  console.log("tratando de leer");
  //console.log(JSON.stringify(retorno));
  //console.log(retorno.props.data);
  console.log("tratando de leer 2");
  
  if(retorno === 0) return useAlert.success("Bienvenido");
}

/**
 * Fin de la definicion de constantes y control de la info de la base de datos
 */

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                    <CardAvatar profile className={classes.cardAvatar}>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img src={avatar} alt="..." />
                      </a>
                    </CardAvatar>
                    <h4 color="black">Inicio de Sesión</h4>                
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button round onClick={() => {onSubmit()}} color="info">
                  Iniciar Sesión
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
